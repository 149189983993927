@import "~react-awesome-slider/src/core/styles.scss";
.#{$root-element} {
    z-index: 0;
    --slider-height-percentage: 60%;
    --slider-transition-duration: 700ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: darkseagreen;
    --control-button-width: 20%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: darkgrey;
    --control-bullet-active-color: darkseagreen;
    --loader-bar-color: darkseagreen;
    --loader-bar-height: 0px;
    --scale-out-scale: 0.85;

    &__wrapper {
        overflow: hidden;
        margin-left: 20px;
        z-index: 1;
    }

    &__active{
        visibility: inherit;
    }

    //&--exit {
    //    &.#{$root-element}--moveRight,
    //    &.#{$root-element}--moveLeft {
    //        animation: scaleOut var(--slider-transition-duration) both
    //        var(--transition-bezier);
    //    }
    //}
}
